/**
 * Calendar constant
 * that are going to used inside the shop component
 */
export const CALENDAR_MAX_ALLOWED_DAYS = 354;
export const VARIATION_VERTICAL = 'VERTICAL';
export const VARIATION_DESKTOP = 'DESKTOP';
export const VARIATION_TABLET = 'TABLET';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const SHORT_WEEK_DAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const SHORT_WEEK_DAYS_NAME = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const LONG_WEEK_DAYS_NAME = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const SHORT_MONTH_NAME = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const NUMBER_OF_MONTH = 1;
export const DATE_MOBILE_FORMAT_DEFAULT = 'MMM DD'; // Aug 22
export const DATE_MOBILE_FORMAT_DEFAULT_DE = 'DD. MMM'; // 22 Aug
export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const FLEXIBLE_FORMAT_YEAR_MONTH = 'MMMM YYYY'; // <Month, Year>
export const CN_YEAR = '年'; // Year character for zh_CN
export const CN_MONTH = '月'; // Month character for zh_CN
export const CN_FLEXIBLE_FORMAT_YEAR_MONTH = `YYYY${CN_YEAR} M${CN_MONTH}`;
export const DATES = 'dates';
export const MAX_NUMBER_OF_NIGHTS_ALLOWED = 9; // in case of flexible
export const RESET = 'reset';
export const DAY_NUMBER_FOR_WEEKEND = 6;
export const MOMENT_CN_LANG = 'zh-cn';
export const DATE_MONTH_YEAR_FORMAT = 'DD/MM/YYYY';
export const YEAR_MON_DATE_FORMAT = 'YYYY/MM/DD';
export const YEAR_MON_DATE_DOTTED_FORMAT = 'YYYY.MM.DD';
export const DATE_DOTTED_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_VALUE = 'MM/DD/YYYY';

export const GB_LOCALE_PATTERN_HYPHEN = 'en-GB';
export const GB_LOCALE_PATTERN_UNDER_SCORE = 'en_GB';
export const US_LOCALE = 'en-US';
export const JP_LOCALE = 'ja-JP';
export const FR_LOCALE = 'fr-FR';
export const ES_LOCALE = 'es-ES';
export const IT_LOCALE = 'it-IT';
export const PT_BR_LOCALE = 'pt-BR';
export const KR_LOCALE = 'ko-KR';
export const RU_LOCALE = 'ru-RU';
export const DE_LOCALE = 'de-DE';
export const TW_LOCALE = 'zh-TW'; //TODO:to be corrected
export const EL_LOCALE = 'el-GR';
export const ADULT_ONLY = 'isAdultOnly';

export enum SearchFormActiveState {
  CALENDAR_STATE = 'state/calendar',
  DESTINATION_STATE = 'state/destination',
  FORM_STATE = 'state/form',
  DEFAULT_STATE = 'state/default',
  CLOSED_STATE = 'state/closed',
}

export const formattingRequiredLocales = [FR_LOCALE, DE_LOCALE, ES_LOCALE, PT_BR_LOCALE, RU_LOCALE];
