// Styles for Footer go here.
import styled from 'styled-components';
import { baseVariables, toRem, Text, Link } from '@marriott/mi-ui-library';

export const StyledContainer = styled.div`
  @media only ${baseVariables.mediaQuery.lg} {
    margin-left: ${toRem(200)};
    margin-right: ${toRem(200)};
  }

  .add-border {
    border-bottom: solid ${toRem(0.5)} ${baseVariables.color['neutral40']};
  }

  .z-3 {
    z-index: 999 !important;
  }
`;

export const StyledRow = styled.div`
  @media only ${baseVariables.mediaQuery.md} {
    .thin-privacy-items {
      margin-right: ${toRem(8)};
      border-right: solid ${toRem(0.5)} ${baseVariables.color['neutral40']};
      margin-bottom: ${toRem(8)};
    }
  }

  .thin-privacy-items:last-of-type {
    border: none;
    margin-right: none;
  }
`;

export const StyledFooterContainer = styled.div`
  background-color: ${baseVariables.color['base10']};
  .m-accordion-container .accordion-button {
    border-top: ${toRem(0.5)} solid ${baseVariables.color['neutral40']};
  }
`;

export const StyledText = styled(Text)`
  font-size: ${toRem(22)};
`;

export const StyledP = styled.p`
  font-size: ${toRem(22)};
`;

export const StyledLink = styled(Link)`
  font-size: ${toRem(14)} !important;
`;

export const LanguageSelectorContainer = styled.div`
  .footer-language-selector {
    background-color: ${baseVariables.color['base10']};
    padding-left: 0;
  }
`;

export const DestinationWrapper = styled.div`
  line-height: ${toRem(27.5)};
`;

export const DestinationOnTopWrapper = styled.div`
  line-height: ${toRem(27.5)};
  border-bottom: solid ${toRem(0.5)} ${baseVariables.color['neutral40']};
`;

export const LinkWrapper = styled.span`
  a:hover {
    text-decoration: underline;
  }
`;

export const DestinationColumn = styled.div`
  .col-md-3 {
    padding: 0;
  }
`;
