// Imports for external libraries go here.
import { FC, useState, useEffect, useRef } from 'react';
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { SearchFormProps } from './SearchForm.types';
import { StyledSearchFormMobileWrapperDiv, StyledSearchFormDesktopWrapperDiv } from './SearchForm.styles';
import { DropDownModal, InputTextField } from '@marriott/mi-ui-library';
import { SearchField, DatePicker } from '../../molecules'; // search form molecules
import { SearchFormActiveState } from '../../constants';
import { SearchPrimaryBlock, SearchFormSubmitButton } from './SearchForm.primary'; //search form top block consist of datepicker and destination molecules
import { SearchSecondaryBlock } from './SearchForm.secondry'; //search from down block
import { useCheckBreakpoint } from '../../hooks/lib/useCheckBreakpoint';
import clsx from 'clsx';
import { isCNLocale } from '@marriott/shared/mi-helper-utils';

export const SearchFormMobileView = (props: SearchFormProps) => {
  const { localDateFormat, model } = props;
  const isDesktopView = useCheckBreakpoint('viewportL');
  const isTabletView = useCheckBreakpoint('viewportM');
  const isAvailabilityScenario = props?.hideDestination && props?.hideBrandsField;

  /**
   * mobile form view dropdown modal state
   */
  const [formModalState, setMobileFormModalState] = useState(false);
  const [isDatesSelected, setIsDatesSelected] = useState(false);
  const [isDestinationParsed, setIsDestinationParsed] = useState('');

  /**
   * set state to display on mobile
   *
   */
  const [currentFormState, setCurrentFormState] = useState<SearchFormActiveState>(SearchFormActiveState.CLOSED_STATE);
  const openMobileModal = (param?: string) => {
    /**
     * open mobile modal with content data
     * it will handle which state we have to open modal
     * it will consist three state one datepicker, desination and complete mobile view
     */
    const str = param ?? currentFormState;
    switch (str) {
      case SearchFormActiveState.CLOSED_STATE:
        setCurrentFormState(SearchFormActiveState.DESTINATION_STATE);
        break;
      case SearchFormActiveState.CLOSED_STATE_CALENDAR:
        setCurrentFormState(SearchFormActiveState.CALENDAR_STATE);
        break;
      case SearchFormActiveState.DESTINATION_STATE:
        setCurrentFormState(SearchFormActiveState.CALENDAR_STATE);
        break;
      case SearchFormActiveState.CALENDAR_STATE:
        setCurrentFormState(SearchFormActiveState.FORM_STATE);
        break;
      default:
        setMobileFormModalState(true);
        setCurrentFormState(SearchFormActiveState.FORM_STATE);
        break;
    }
  };

  const MobileSearchFormContent: FC<{ contentType: string; isTabbedSearchForm: boolean | string }> = ({
    contentType,
    isTabbedSearchForm,
  }) => {
    /**
     * return content as per the required render data
     */

    switch (contentType) {
      case SearchFormActiveState.DESTINATION_STATE:
        /**
         * return destination field if state == destination
         */
        return (
          <SearchField
            onChange={(inputValue: string) => {
              !isDatesSelected
                ? setCurrentFormState(SearchFormActiveState.CALENDAR_STATE)
                : isTabbedSearchForm
                ? setCurrentFormState(SearchFormActiveState.DEFAULT_STATE)
                : setCurrentFormState(SearchFormActiveState.FORM_STATE);
              setMobileFormModalState(true);
              setIsDestinationParsed(inputValue);
            }}
            onCancel={() => {
              setCurrentFormState(SearchFormActiveState.CLOSED_STATE);
            }}
            isDesktopView={isDesktopView}
            isModalOpen={true}
            mobileModalHeading={model?.destinationModalHeader}
            mobilePlaceHolderText={model?.expandedPlaceHolderTextMobile}
          />
        );
      case SearchFormActiveState.CALENDAR_STATE:
        /**
         * return destination field if state == calendar
         */
        return (
          <DatePicker
            onDatePickerChange={() => {
              setMobileFormModalState(true);
              setIsDatesSelected(true);
              !isDestinationParsed
                ? setCurrentFormState(SearchFormActiveState.DESTINATION_STATE)
                : isTabbedSearchForm
                ? setCurrentFormState(SearchFormActiveState.DEFAULT_STATE)
                : setCurrentFormState(SearchFormActiveState.FORM_STATE);
            }}
            isModalOpen={true}
            onDatePickerCancel={() => {
              setCurrentFormState(SearchFormActiveState.CLOSED_STATE);
            }}
            setDefaultDate={true}
          />
        );
      case SearchFormActiveState.FORM_STATE:
        /**
         * return destination field if state == form
         * display whole form with modal
         */
        return (
          <DropDownModal
            applyDefaultHeight={isTabbedSearchForm === true ? true : false}
            className="mobile-search-form"
            role="dialog"
            childrenClassName={'search-form-dropdown'}
            show={formModalState}
            dropdownModalOpenState={true}
            setDropdownModalOpenState={setMobileFormModalState}
            // mobile setting enabled
            mobileTakeOver={true}
            mobileModalHeading={model?.searchLabel}
            mobileHeaderEnabled={true}
            dropdownModalOnCLoseFunc={() => {
              setMobileFormModalState(false);
              !isDesktopView && bodyRef.current && (bodyRef.current.style.overflow = 'auto'); // Enable scrolling on the background
              setCurrentFormState(SearchFormActiveState.CLOSED_STATE);
            }}
            customLastElementRef={buttonRef}
          >
            <SearchPrimaryBlock
              isButtonHide={true}
              alertsListArray={model?.alertsListArray}
              isDesktopView={isDesktopView}
              submitAction={model?.submitAction}
              mobileModalHeading={model?.destinationModalHeader}
              mobilePlaceHolderText={model?.placeholderTextDesktop}
              isMobileForm={true}
              baselineComponentTitle={model?.baselineComponentTitle}
              isAvailabilityScenario={isAvailabilityScenario}
              localDateFormat={localDateFormat}
            />
            {!(model?.disableRoomGuest && model?.disableSpecialRates) && model?.enableRedeemPoints && (
              <SearchSecondaryBlock
                isDesktopView={isDesktopView}
                isTabletView={isTabletView}
                submitAction={model?.submitAction}
                isAvailabilityScenario={isAvailabilityScenario}
                localDateFormat={localDateFormat}
                sessionData={props?.sessionData}
              />
            )}
            {isTabbedSearchForm !== true && (
              <SearchFormSubmitButton
                findHotelLabel={model?.findHotelLabel}
                submitAction={model?.submitAction}
                lastElementRef={buttonRef}
                localDateFormat={localDateFormat}
                sessionData={props?.sessionData}
                isAvailabilityScenario={isAvailabilityScenario}
              />
            )}
          </DropDownModal>
        );

      /**
       * add any other case if required
       */
      default:
        /**
         * TODO://  need to return some default content
         * will check and update
         */
        return <div></div>;
    }
  };

  const bodyRef = useRef<HTMLBodyElement | null>(null);

  useEffect(() => {
    bodyRef.current = document.body as HTMLBodyElement;
  }, []);

  useEffect(() => {
    if (formModalState === true && !isDesktopView && bodyRef.current) {
      bodyRef.current.style.overflow = 'hidden'; // Disable scrolling on the background
    } else {
      !isDesktopView && bodyRef.current && (bodyRef.current.style.overflow = 'auto'); // Enable scrolling on the background
    }
  }, [formModalState]);
  const buttonRef = useRef<HTMLButtonElement>(null); //ref to the last element of the search field, to be used in dropdown modal to identify the last element of the modal

  return (
    <StyledSearchFormMobileWrapperDiv
      $isTabbedSearchForm={model.isTabbedSearchForm}
      tabIndex={0}
      className={clsx(
        model.isTabbedSearchForm ? '' : 'custom-padding',
        !model.isTabbedSearchForm ? 'd-flex d-lg-none' : ''
      )}
    >
      {/* form view by deafult */}
      {model?.isTabbedSearchForm ? (
        <div className="pt-4 tabbed-search-form-container pt-md-4 pt-lg-4 ">
          <div className={clsx('mobile-wrapper-default')}>
            <SearchPrimaryBlock
              isButtonHide={true}
              alertsListArray={model?.alertsListArray}
              isDesktopView={isDesktopView}
              submitAction={model?.submitAction}
              isDatesSelected={isDatesSelected}
              setCurrentFormState={setCurrentFormState}
              setMobileFormModalState={setMobileFormModalState}
              setIsDestinationParsed={setIsDestinationParsed}
              setIsDatesSelected={setIsDatesSelected}
              isDestinationParsed={isDestinationParsed}
              isTabletView={isTabletView}
              mobileModalHeading={model?.destinationModalHeader}
              mobilePlaceHolderText={model?.placeholderTextMobile}
              isMobileForm={true}
              baselineComponentTitle={model?.baselineComponentTitle}
              isAvailabilityScenario={isAvailabilityScenario}
              localDateFormat={localDateFormat}
            />
            <SearchSecondaryBlock
              isTabletView={isTabletView}
              isDesktopView={isDesktopView}
              submitAction={model?.submitAction}
              isAvailabilityScenario={isAvailabilityScenario}
              localDateFormat={localDateFormat}
              sessionData={props?.sessionData}
            />
            {/* display submit button on mobile view only */}
            {model?.isTabbedSearchForm && (
              <SearchFormSubmitButton
                customClass="d-md-none d-sm-block"
                findHotelLabel={model?.submitCTA}
                submitAction={model?.submitAction}
                alertsListArray={model?.alertsListArray}
                lastElementRef={buttonRef}
                localDateFormat={localDateFormat}
                sessionData={props?.sessionData}
                isAvailabilityScenario={isAvailabilityScenario}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          {/* 2 pills layout view by deafult */}
          <div
            onClick={() => {
              openMobileModal(SearchFormActiveState.CLOSED_STATE);
            }}
            onKeyDown={event => {
              if (event.code === '13' || event.key === 'Enter') {
                openMobileModal(SearchFormActiveState.CLOSED_STATE);
              }
            }}
            data-testid="destination"
          >
            <InputTextField
              classNameForLineType="mobile-homepage-button text-left d-lg-none dashed-line"
              getInputProps={() => ({
                readOnly: true,
              })}
              label={model?.destinationModuleEyebrowText}
              iconForLabel="icon-location"
              placeHolderText={model?.placeholderTextMobile}
              inputTextFieldClassName="dashed-line mobile-view-font"
              variation="line-type"
            />
          </div>
          <div
            onClick={() => {
              openMobileModal(SearchFormActiveState.CLOSED_STATE_CALENDAR);
            }}
            onKeyDown={event => {
              if (event.code === '13' || event.key === 'Enter') {
                openMobileModal(SearchFormActiveState.CLOSED_STATE_CALENDAR);
              }
            }}
            data-testid="dates"
          >
            <InputTextField
              classNameForLineType="mobile-homepage-date-field"
              iconForLabel="icon-stay-dates"
              getInputProps={() => ({
                readOnly: true,
              })}
              label={model?.dateModuleEyebrowText}
              placeHolderText={model?.dateHelperTextMobile}
              inputTextFieldClassName="mobile-view-font"
              variation="line-type"
            ></InputTextField>
          </div>
        </>
      )}

      <MobileSearchFormContent isTabbedSearchForm={model.isTabbedSearchForm} contentType={currentFormState} />
    </StyledSearchFormMobileWrapperDiv>
  );
};

export const SearchFormDesktopView = (props: SearchFormProps) => {
  const { localDateFormat, model, hideBrandsField, hideDestination, sessionData, currentLocale } = props;
  const isDesktopView = useCheckBreakpoint('viewportL');
  const isTabletView = useCheckBreakpoint('viewportM');
  const isAvailabilityScenario = hideDestination && hideBrandsField;
  return (
    /**
     * desktop view component
     */
    <StyledSearchFormDesktopWrapperDiv
      className={clsx(
        model?.isTabbedSearchForm ? 'pt-lg-4 pt-0' : '',
        !model.isTabbedSearchForm ? 'd-none d-lg-block' : '',
        hideBrandsField === true && hideDestination === true ? 'availibilty-search-container d-flex' : ''
      )}
    >
      <SearchPrimaryBlock
        findHotelLabel={model?.findHotelLabel}
        alertsListArray={model?.alertsListArray}
        isButtonHide={false}
        isDesktopView={isDesktopView}
        submitAction={model?.submitAction}
        isTabletView={isTabletView}
        mobileModalHeading={
          model?.isTabbedSearchForm ? model?.destinationModalHeader : model?.expandedPlaceHolderTextMobile
        }
        mobilePlaceHolderText={model?.placeholderTextMobile}
        isSearchFormSticky={props.isSearchFormSticky}
        baselineComponentTitle={model?.baselineComponentTitle}
        isAvailabilityScenario={isAvailabilityScenario}
        localDateFormat={localDateFormat}
      />

      {(!(model?.disableRoomGuest && model?.disableSpecialRates) || model?.enableRedeemPoints) && (
        <SearchSecondaryBlock
          submitAction={model?.submitAction}
          isTabletView={isTabletView}
          isDesktopView={isDesktopView}
          isAvailabilityScenario={isAvailabilityScenario}
          localDateFormat={localDateFormat}
          sessionData={sessionData}
        />
      )}
      {model?.isTabbedSearchForm !== true && hideDestination !== true ? (
        <SearchFormSubmitButton
          findHotelLabel={model?.findHotelLabel}
          submitAction={model?.submitAction}
          customClass={`d-none d-lg-block searchfrom-submit-btn ${
            isCNLocale(currentLocale) ? 'cn-searchfrom-submit-btn' : ''
          }`}
          localDateFormat={localDateFormat}
          sessionData={sessionData}
          isAvailabilityScenario={isAvailabilityScenario}
        />
      ) : (
        ''
      )}
    </StyledSearchFormDesktopWrapperDiv>
  );
};
