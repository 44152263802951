import { FC } from 'react';
import { Link } from '@marriott/mi-ui-library';

import { getClickTrackValue } from '../../utils/TrackingPropertyUtils';

import { FooterAEMModel } from './Footer.types';
import { StyledRow, LinkWrapper } from './Footer.styles';
import { DEFAULT_TRACKING_VALUES } from '../../utils/constants';

import { parseBoolean } from './helper';

export const CollapsedFooter: FC<{ model: FooterAEMModel }> = ({ model }) => {
  const trackingPropertiesObject = {
    trackingProperties: {
      trackingContentPosition: model?.trackingProperties?.trackingContentPosition,
      clickTrack: model?.trackingProperties?.clickTrack,
      trackingOfferType: model?.trackingProperties?.trackingOfferType,
      impressionCount: model?.trackingProperties?.impressionCount,
      impressionTrack: model?.trackingProperties?.impressionTrack,
      trackingDescription: model?.trackingProperties?.trackingDescription,
      trackingTag: model?.trackingProperties?.trackingTag,
    },
    fallbacks: {
      position: model?.trackingProperties?.location ?? DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
      description: model?.trackingProperties?.trackingDescription ?? DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
    },
  };
  return (
    <div className="col-12 inverse px-4 pt-3">
      <div className="mt-5">
        <StyledRow className="d-flex flex-column flex-md-row row">
          {model?.minimizedItems?.map(minimizedItem => {
            return (
              <div key={minimizedItem.linkLabel} className="col-md-1.5 pl-md-0 thin-privacy-items">
                <LinkWrapper>
                  <Link
                    target={parseBoolean(minimizedItem.openInaNewTab) ? '_blank' : '_self'}
                    linkHref={minimizedItem.linkURL}
                    text={minimizedItem.linkLabel}
                    linkClassName="t-font-alt-s mr-2"
                    custom_click_track_value={getClickTrackValue(trackingPropertiesObject, minimizedItem.linkLabel)}
                    trackingProperties={trackingPropertiesObject.trackingProperties}
                  />
                </LinkWrapper>
              </div>
            );
          })}
        </StyledRow>
      </div>
    </div>
  );
};
