/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { FC, useEffect, useRef, useState, KeyboardEvent } from 'react';
import clsx from 'clsx';

import { DropDownModal, CustomSelectBlock, RadioButton, InputTextField } from '@marriott/mi-ui-library';

import { SpecialRatesProps } from './SpecialRates.types';
import { StyledSpecialRatesDiv } from './SpecialRates.styles';

import {
  SPECIAL_RATES,
  USE_POINTS,
  FOCUS_INTERACTIONS,
  CORP,
  SPECIAL_RATES_DONE_CLICK_TRACK,
  SPECIAL_RATES_CLOSE_CLICK_TRACK,
} from '../../modules/store/store.constants';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { useStaticDataContext } from '../../modules/context/StaticDataContext';
import { ELEMENT_BUTTON, ELEMENT_INPUT, searchFormClickTrackingLoc } from '../../constants/lib/constants';
import { useCheckBreakpoint } from '../../hooks/lib/useCheckBreakpoint';
declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    custom_click_track_value?: string;
  }
}

export const SpecialRates: FC<SpecialRatesProps> = ({
  onCancel,
  isADFPage = false,
  isDesktopView,
  addLabelSpacingForArcTool = false,
  isAvailabilityScenario,
  errorMessage,
}) => {
  const compRef = useRef<HTMLDivElement>(null);
  const setSearchFormState = useSearchFormStore((state: any) => state.setSearchFormState);
  const splRatesHiddenFields = useSearchFormStore((state: any) => state.specialRates);
  const usePointsValue = useSearchFormStore((state: any) => state.usePoints);
  const [previousSpecialRates, setPreviousSpecialRates] = useState<any>({
    corporateCode: '',
    specialRateCode: 'none',
    clusterCode: '',
  });
  const [popupOpenState, setPopupOpenState] = React.useState(false);
  const searchFormTopPosition = useSearchFormStore((state: any) => state?.searchFormTopPosition);

  const {
    specialRatesListItems,
    specialRatesEyebrowText,
    enterCodeEyebrowText,
    instructiveSpecialRates,
    specialRatesApplyCta,
    isTabbedSearchForm,
    roomsAndGuestsResetCta,
  } = useStaticDataContext();
  const isMobileView = useCheckBreakpoint('viewportS');

  //TODO- Move corpCodeValue and groupValue to searchform constant file and import it from there
  const corpCodeValue = 'corp';
  const groupValue = 'group';
  const specialRatesList = specialRatesListItems as Array<Record<string, string>>;
  let specialRateDefaultLabel = '';
  specialRatesList?.forEach(obj => {
    if (obj['specialRatesCode'] === splRatesHiddenFields?.specialRateCode) {
      specialRateDefaultLabel = obj['specialRateslabel'];
    }
  });
  const [showIcon, setShowIcon] = useState(splRatesHiddenFields?.corporateCode ? true : false);

  const bodyRef = useRef<HTMLBodyElement | null>(null);
  useEffect(() => {
    bodyRef.current = document.body as HTMLBodyElement;
  }, []);

  const clickTrackingLoc = 'Search Form';

  // to show popup on desktop on button click
  const showPopup = (): void => {
    setPopupOpenState(true);
    //TODO : this is creating issue with renders , need to check
    //capture user interactions
    setSearchFormState([FOCUS_INTERACTIONS], { [FOCUS_INTERACTIONS]: { isUserInteractWithComp: true } });
  };

  const handleIconPopUpClick = (): void => {
    setPopupOpenState(!popupOpenState);
    //capture user interactions
    setSearchFormState([FOCUS_INTERACTIONS], { [FOCUS_INTERACTIONS]: { isUserInteractWithComp: true } });
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clusterCode = urlParams?.get('clusterCode') || urlParams?.get('cc');
    if (clusterCode && isAvailabilityScenario) {
      const existingCode = specialRatesList?.filter(obj => obj['specialRatesCode'] === clusterCode?.toLowerCase())[0];
      if (existingCode) {
        specialRateDefaultLabel = existingCode['specialRateslabel'];
        setSearchFormState([SPECIAL_RATES, USE_POINTS], {
          [SPECIAL_RATES]: {
            specialRateCode: existingCode['specialRatesCode'],
            corporateCode: '',
            clusterCode: existingCode['specialRateslabel'],
          },
          [USE_POINTS]: {
            rewardsRedemption: clusterCode?.toLowerCase() !== 'none' ? false : usePointsValue?.rewardsRedemption,
          },
        });
      } else {
        specialRateDefaultLabel = clusterCode;
        setSearchFormState([SPECIAL_RATES, USE_POINTS], {
          [SPECIAL_RATES]: {
            specialRateCode: 'corp',
            corporateCode: clusterCode,
            clusterCode: clusterCode,
          },
          [USE_POINTS]: {
            rewardsRedemption: clusterCode?.toLowerCase() !== 'none' ? false : usePointsValue?.rewardsRedemption,
          },
        });
      }
    } else return;
  }, []);
  useEffect(() => {
    if (popupOpenState || !isDesktopView) {
      if (splRatesHiddenFields?.corporateCode || splRatesHiddenFields?.specialRateCode === 'corp') {
        const textField = compRef.current?.querySelector(`[maxLength="9"][type="text"]`) as HTMLElement;
        textField?.focus();
      } else {
        setFocusOnFirstBtn();
      }
    }

    /**
     * Disable the background scroll when popup is open in Tabbed Searchform
     * */
    if (isTabbedSearchForm && bodyRef.current) {
      if (popupOpenState === true) {
        !isDesktopView && isTabbedSearchForm && (bodyRef.current.style.overflow = 'hidden'); // Disable scrolling on the background
      } else {
        bodyRef.current.style.overflow = 'auto';
      }
    }

    if (
      !popupOpenState &&
      (splRatesHiddenFields?.specialRateCode === 'corp' || splRatesHiddenFields?.specialRateCode === 'group') &&
      !splRatesHiddenFields?.corporateCode?.trim()
    ) {
      setSearchFormState([SPECIAL_RATES], {
        [SPECIAL_RATES]: {
          specialRateCode: 'none',
          corporateCode: '',
          clusterCode: 'Lowest Regular Rate',
        },
      });
    }
  }, [popupOpenState]);

  const setFocusOnFirstBtn = (): void => {
    const popupElemBtn = compRef.current?.querySelector(
      ".special-rates-popup .radio-label input[type='radio']"
    ) as HTMLElement;
    popupElemBtn?.focus();
  };

  const keyHandler = (value: string): void => {
    if (!value) {
      const textField = compRef.current?.querySelector('[name="corporateCode"]') as HTMLElement;
      textField?.focus();
    }
  };

  useEffect(() => {
    // Focus on the textfiled, When corp option is selected
    if (splRatesHiddenFields?.specialRateCode?.toLowerCase() === CORP) {
      const textField = compRef.current?.querySelector('[name="input-text-ENTER CODE"]') as HTMLElement;
      textField?.focus();
    }
  }, [splRatesHiddenFields?.specialRateCode]);

  // to handle radioButton selections
  const changeHandler = (value: string, label: string): void => {
    setSearchFormState([SPECIAL_RATES, USE_POINTS], {
      [SPECIAL_RATES]: {
        specialRateCode: value,
        corporateCode: '',
        clusterCode: label,
      },
      [USE_POINTS]: {
        rewardsRedemption: value !== 'none' ? false : usePointsValue?.rewardsRedemption,
      },
    });
  };

  const returnDescription = () => {
    let specialLabel = specialRateDefaultLabel;
    if (splRatesHiddenFields?.specialRateCode?.toLowerCase() === corpCodeValue) {
      if (splRatesHiddenFields?.corporateCode) {
        specialLabel = splRatesHiddenFields?.corporateCode;
      }
    }
    if (splRatesHiddenFields?.specialRateCode?.toLowerCase() === groupValue) {
      if (splRatesHiddenFields?.corporateCode) {
        specialLabel = splRatesHiddenFields?.corporateCode;
      }
    }
    return specialLabel;
  };
  const handleInputChange = (returnedValue: string) => {
    if (returnedValue) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
    setSearchFormState([SPECIAL_RATES], {
      [SPECIAL_RATES]: {
        ...splRatesHiddenFields,
        corporateCode: returnedValue,
      },
    });
  };
  const clearInput = () => {
    setSearchFormState([SPECIAL_RATES], {
      [SPECIAL_RATES]: {
        ...splRatesHiddenFields,
        corporateCode: '',
      },
    });
  };

  const onKeyDown = (e: KeyboardEvent<HTMLElement>, value: string, label: string): void => {
    if (e.keyCode === 13) {
      setSearchFormState([SPECIAL_RATES, USE_POINTS], {
        [SPECIAL_RATES]: {
          specialRateCode: value,
          corporateCode: '',
          clusterCode: label,
        },
        [USE_POINTS]: {
          rewardsRedemption: value !== 'none' ? false : usePointsValue?.rewardsRedemption,
        },
      });
    }
  };

  const handleIconClick = (e: KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      clearInput();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the click target is not within the popup or the button or the icons
      if (
        isTabbedSearchForm === true &&
        !event.target.closest('.tabbed-dropdown-container') &&
        !event.target.closest('.special-rates-input-container') &&
        !event.target.closest('.special-rates-arrow-icon')
      ) {
        setPopupOpenState(false);
      }
    };
    const focusoutListener = (event: FocusEvent): void => {
      if (
        event.relatedTarget &&
        !compRef?.current?.contains(event.relatedTarget as Element) &&
        !compRef?.current?.contains(document.activeElement) &&
        compRef?.current?.contains(event.target as Element) &&
        ((event.target as HTMLInputElement).tagName?.toLowerCase() === ELEMENT_INPUT ||
          (event.target as HTMLInputElement).tagName?.toLowerCase() === ELEMENT_BUTTON)
        //making this change to restrict the unwated close of dropdown on clicking of white spaces
      ) {
        if (setPopupOpenState) {
          setPopupOpenState(false);
        }
      }
    };
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    compRef?.current?.addEventListener('focusout', focusoutListener); // Attaching focusout event to dropdown to handle blur event

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      compRef?.current?.removeEventListener('focusout', focusoutListener);
    };
  }, []);

  const isGroupCode = splRatesHiddenFields?.specialRateCode === groupValue;
  const groupCodeMaxLength = isAvailabilityScenario && isGroupCode ? 7 : 9;
  const codeLength = splRatesHiddenFields?.corporateCode?.length;
  const showErrorMessage =
    showIcon &&
    isAvailabilityScenario &&
    Boolean(codeLength) &&
    (isGroupCode ? codeLength < groupCodeMaxLength : codeLength < 2);

  return (
    <StyledSpecialRatesDiv
      data-component-name="m-shop-SpecialRates"
      data-testid="shop-SpecialRates"
      onClick={() => {
        !isDesktopView && !popupOpenState && showPopup();
      }}
      className="special-rates-block"
    >
      {isTabbedSearchForm ? (
        <InputTextField
          custom_click_track_value={`${searchFormClickTrackingLoc} | Special Rates |internal`}
          handleKeyDown={() => {
            setPopupOpenState(!popupOpenState);
          }}
          className={'m-input-text-field special-rates-input-container'} // these 2 class names are mandatory , please do not remove
          label={addLabelSpacingForArcTool ? specialRatesEyebrowText + ' ' : specialRatesEyebrowText}
          inputValue={returnDescription()}
          onFocus={showPopup}
          showIcon={true}
          iconClass={popupOpenState ? 'icon-arrow-up' : 'icon-arrow-down'}
          getInputProps={() => ({
            readOnly: true,
          })}
          isFocusEnabled={isTabbedSearchForm && false}
          isClickEnabled={isTabbedSearchForm && true}
          inputTextFieldClassName={clsx('special-rates-text', popupOpenState ? 'input-with-border' : '')}
          variation={'default'}
          withinModal={true}
          showUnderline={true}
          iconOnClick={handleIconPopUpClick}
          iconAriaLabel={'Special Rates Dropdown'}
          trailingIconProps={{
            className: clsx('trailing-element', 'special-rates-arrow-icon'), // these 2 class names are mandatory , please do not remove
            'aria-expanded': popupOpenState,
            onKeyDown: (e: KeyboardEvent) => {
              if (e.key === 'Enter' || e.code === '13') {
                handleIconPopUpClick();
              }
            },
          }}
        ></InputTextField>
      ) : !isDesktopView ? (
        <InputTextField
          handleKeyDown={() => {
            setPopupOpenState(!popupOpenState);
          }}
          isClickEnabled={true}
          custom_click_track_value={`${searchFormClickTrackingLoc} | Special Rates |internal`}
          label={addLabelSpacingForArcTool ? specialRatesEyebrowText + ' ' : specialRatesEyebrowText}
          inputValue={returnDescription()}
          showIcon={true}
          iconClass="icon-arrow-right"
          getInputProps={() => ({
            readOnly: true,
          })}
          variation="line-type"
          withinModal={true}
          showUnderline={true}
          iconOnClick={showPopup}
          trailingIconProps={{
            'aria-expanded': popupOpenState,
            onKeyDown: (e: KeyboardEvent) => {
              if (e.key === 'Enter' || e.code === '13') {
                handleIconPopUpClick();
              }
            },
          }}
        ></InputTextField>
      ) : (
        <CustomSelectBlock
          selectTitle={''}
          selectDescription={returnDescription()}
          handleClick={showPopup}
          name={specialRatesEyebrowText}
          id={specialRatesEyebrowText}
          isExpanded={popupOpenState}
          customButtonClass="t-font-weight-m t-font-xs"
          customClickTrackValue={`${searchFormClickTrackingLoc} | Special Rates |internal`}
        ></CustomSelectBlock>
      )}

      <div ref={compRef}>
        <DropDownModal
          shouldAutoFocusOnPopup={true}
          isAutoScrollEnabled={true}
          parentElementTopValue={searchFormTopPosition?.value}
          applyDefaultHeight={isTabbedSearchForm === true ? true : false}
          className={clsx(isTabbedSearchForm && 'tabbed-dropdown-container', 'dropdown-container')}
          childrenClassName={`special-rates-dropdown ${isTabbedSearchForm && 'm-dropdown-cont'}`}
          show={popupOpenState}
          clearButtonLabel={roomsAndGuestsResetCta}
          applyLabel={specialRatesApplyCta}
          applyBtnClickHandler={() => {
            if (!showErrorMessage) {
              setPreviousSpecialRates(splRatesHiddenFields);
              setPopupOpenState(false);
            }
          }}
          dropDownSpecificFlag={isTabbedSearchForm === true ? false : true}
          mobileModalHeading={specialRatesEyebrowText}
          dropdownModalCloseClassName={'special-rates-close'}
          clickTrackingLoc={'Special Rates dropdown'}
          role={'dialog'}
          // label={addLabelSpacingForArcTool ? specialRatesEyebrowText + ' ' : specialRatesEyebrowText}
          labelledBy={addLabelSpacingForArcTool ? 'container' : 'containerSecondary'}
          mobileTakeOver={!isDesktopView ? true : false}
          handleBlur={isDesktopView ? true : false}
          mobileHeaderEnabled={true}
          mobileFooterEnabled={true}
          scrollDownModalCloseState={false}
          setDropdownModalOpenState={setPopupOpenState}
          dropdownModalOpenState={popupOpenState}
          dropdownModalOnCLoseFunc={() => {
            if (!showErrorMessage) {
              setTimeout(() => {
                //using use timeout as ADA is not working without it
                onCancel?.();
                setPopupOpenState(false);
              }, 100);
            }
          }}
          clearClickHandler={() => {
            setSearchFormState([SPECIAL_RATES], {
              [SPECIAL_RATES]: {
                specialRateCode: previousSpecialRates?.specialRateCode,
                corporateCode: '',
                clusterCode: previousSpecialRates?.clusterCode,
              },
            });
          }}
        >
          <>
            <div
              tabIndex={-1}
              className={clsx('t-label-xs', isTabbedSearchForm && isMobileView && !isDesktopView ? 'pb-5' : 'pb-3')}
            >
              {instructiveSpecialRates?.toUpperCase()}
            </div>
            <div role="radiogroup">
              {specialRatesList?.map((element: Record<string, string>, index: number) => {
                // to stop group option being shown on the SERP Page
                if (!isADFPage && element['specialRatesCode'] === groupValue) {
                  return null;
                }

                return (
                  <div
                    tabIndex={-1}
                    key={element['specialRatesCode']}
                    className={clsx('spl-rates-radio-container', 'm-radio-button-xs')}
                  >
                    <RadioButton
                      dataTestId={element['specialRatesCode'] === 'corp' ? 'option-radio-btn' : ''}
                      //radiobuttonId={`clusterCode${index}`}
                      radiobuttonId={
                        addLabelSpacingForArcTool ? `clusterCode${index}` : `clusterCode${index}-secondary`
                      }
                      radiobuttonName={
                        addLabelSpacingForArcTool ? element['specialRatesCode'] + 'tablet' : element['specialRatesCode']
                      }
                      radiobuttonLabel={
                        addLabelSpacingForArcTool ? element['specialRateslabel'] : `${element['specialRateslabel']} ` //do not remove this extra white space added at the end
                      }
                      customClickClass="custom_click_track"
                      checked={
                        splRatesHiddenFields?.specialRateCode?.toLowerCase() ===
                        element['specialRatesCode']?.toLowerCase()
                      }
                      onChange={({ target: { value } }): void => changeHandler(value, element['specialRateslabel'])}
                      value={element['specialRatesCode']}
                      custom_click_track_value={`${clickTrackingLoc}| ${element['specialRateslabel']} |internal`}
                      disabled={false}
                      setTabIndex={-1}
                      labelClassName={'fontWeight'}
                      className={clsx(isTabbedSearchForm && 'tabbed-radio-label', 'radio-label', 'py-3')}
                      onKeyDown={onKeyDown}
                    />

                    {(element['specialRatesCode']?.toLowerCase() === corpCodeValue ||
                      (isADFPage && element['specialRatesCode'] === groupValue)) && // to show the textfield when corp/group option is selected
                      element['specialRatesCode']?.toLowerCase() ===
                        splRatesHiddenFields?.specialRateCode?.toLowerCase() && (
                        <div
                          className={clsx(
                            'custom-input-padding',
                            showErrorMessage
                              ? 'availability-corp-code'
                              : isTabbedSearchForm && !isAvailabilityScenario
                              ? 'pt-3 tabbed-promo-code'
                              : 'top-spacing-class',
                            isDesktopView ? 'crop-promo-code search-form-promo-code' : 'crop-promo-code'
                          )}
                        >
                          <InputTextField
                            label={enterCodeEyebrowText}
                            inputValue={splRatesHiddenFields?.corporateCode ?? ''}
                            getInputValue={handleInputChange}
                            handleKeyDown={keyHandler}
                            //TODO- Should be authorable
                            inputMaxLength={groupCodeMaxLength}
                            showIcon={showIcon || Boolean(splRatesHiddenFields?.corporateCode)}
                            iconClass={
                              splRatesHiddenFields?.corporateCode?.length > 0 ? 'icon-cancel custom_click_track' : ''
                            }
                            iconOnClick={clearInput}
                            infoLabel="special-rates"
                            variation="line-type"
                            withinModal={true}
                            showUnderline={true}
                            inputTextFieldClassName={!isDesktopView ? '' : 'desktop-font-text-class'}
                            trailingIconProps={{ onKeyDown: handleIconClick, role: 'button' }}
                            icon_custom_click_track_value={SPECIAL_RATES_CLOSE_CLICK_TRACK}
                            iconAriaLabel={'Clear'}
                            showErrorMessage={showErrorMessage}
                            messageToShow={errorMessage?.alertMessage}
                            messageClass="error-message"
                          />
                          <div className={clsx(isTabbedSearchForm && 'apply-btn', 'apply-button-desktop')}>
                            {isDesktopView && (
                              <button
                                type="button"
                                onClick={() => {
                                  if (!showErrorMessage) {
                                    setPopupOpenState(false);
                                  }
                                }}
                                className="m-button-secondary m-button-s custom_click_track"
                                custom_click_track_value={SPECIAL_RATES_DONE_CLICK_TRACK}
                              >
                                {specialRatesApplyCta}
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
          </>
        </DropDownModal>
      </div>
    </StyledSpecialRatesDiv>
  );
};
