/**
 * Calendar constant
 * that are going to used inside the shop component
 */
export const CALENDAR_MAX_ALLOWED_DAYS = 364;
export const VARIATION_VERTICAL = 'VERTICAL';
export const VARIATION_DESKTOP = 'DESKTOP';
export const VARIATION_TABLET = 'TABLET';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const SHORT_WEEK_DAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const SHORT_WEEK_DAYS_NAME = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const LONG_WEEK_DAYS_NAME = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const SHORT_MONTH_NAME = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const NUMBER_OF_MONTH = 1;
export const DATE_MOBILE_FORMAT_DEFAULT = 'MMM DD'; // Aug 22
export const DATE_MOBILE_FORMAT_DEFAULT_DE = 'DD. MMM'; // 22 Aug
export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const FLEXIBLE_FORMAT_YEAR_MONTH = 'MMMM YYYY'; // <Month, Year>
export const DATE_FORMAT_MM_DD_VALUE = 'MM/DD/YYYY';
export const DATES = 'dates';
export const RESET = 'reset';
export const TOO_MANY_ROOMS_FOR_FLEXIBLE_ERROR = 'tooManyRoomsForFlexibleDates';
export const GROUP_CODE_FEW_CHARS_ERROR = 'groupCodeHasFewerCharacters';
export const SM_VIEW_RATES_URL = '?propertyCode=';
//TODO:to be read from interceptor req
export const SM_VIEW_RATES_ADF_URL = '?isRateCalendar=true&propertyCode=';
//TODO:to be moved to env
export const NEXT_PUBLIC_PRE_PROCESSING_ADF_URL =
  'http://mi-shop-app-phoenix-searchtest1.ose-dev39-red.aws-use1.cloud.marriott.com/validate/availabilitySearch';
export const NEXT_PUBLIC_PRE_PROCESSING_RELATIVE_ADF_URL = '/validate/availabilitySearch';
// const to reference corp code error from AEM
export const CORP_CODE_CHARS_LENGTH_ERROR = 'corpCodeInvalidCharLength';
export const CORP_CODE_CHARS_INVALID_ERROR = 'corporateCodeFormatInvalid';
// consts to identify the special rate type from the form values obj
export const CORP_CLUSTER_CODE = 'corp';
export const GROUP_CLUSTER_CODE = 'group';
// const to reference absent checkin date error from AEM
export const NO_CHECK_IN_DATE_ERROR = 'noInputDateSelected';
// const to reference future date error
export const FUTURE_DATE_ERRROR = 'checkInDateMoreThanError';
export const ADF_CONTINUE_CLICK_TRACK = 'ADF|continue button|internal';

export enum SearchFormActiveState {
  CALENDAR_STATE = 'state/calendar',
  DESTINATION_STATE = 'state/destination',
  FORM_STATE = 'state/form',
  DEFAULT_STATE = 'state/default',
  CLOSED_STATE = 'state/closed',
  CLOSED_STATE_CALENDAR = 'state/closed-calendar',
}

export const POINTS_PER_UNIT = 'points-per-unit';
export const SUBTOTAL_PER_UNIT = 'subtotal-per-unit';
export const AVERAGE_NIGHTLY_RATE_PER_UNIT = 'average-nightly-rate-per-unit';
export const TOTAL_GUEST_NON_VIEWABLE_RATE_PER_UNIT = 'total-guest-non-viewable-rate-per-unit';
export const AVG_TAB = 'avgTab';
export const TOTAL_TAB = 'totalTab';
export const NUMBER_OF_DAYS_FOR_PREVIOUS_MONTH = 6;
export const TOTAL_NUMBER_OF_DAYS_FOR_DATA = 41;
export const DESKTOP_CURRENCY_LENGTH = 15;
export const TABLET_CURRENCY_LENGTH = 11;
export const MOBILE_CURRENCY_LENGTH = 5;

export const DEFAULT_SINGLE_DATE_LIMIT = 355;
export const DEFAULT_GROUP_DATE_LIMIT = 706;

export const DISABLE_DISTANCE = true;
