import React, { ChangeEvent, FC } from 'react';
import { RichTextProps } from './RichText.types';
import { StyledRichText } from './RichText.styles';
import clsx from 'clsx';

/* const addLinkActionClass = (html: string): string => {
  if (typeof window !== 'undefined' && typeof DOMParser !== 'undefined') {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const anchorTags = doc.querySelectorAll('a[href]');

    anchorTags.forEach(anchor => {
      const hasUnderlineChild = anchor.querySelector('u') !== null;
      if (!hasUnderlineChild) {
        const target = anchor.getAttribute('target');
        if (target === '_blank') {
          anchor.classList.add('m-link-tertiary-button-external');
        } else {
          anchor.classList.add('m-link-action');
        }
      }
    });

    return doc.body.innerHTML;
  }

  // Return the original HTML if DOMParser is not available
  return html;
};
*/

export const RichText: FC<RichTextProps> = props => {
  const { text, componentId, styleclass, customClass, contentEditable, onChange, ...customProps } = props;
  const handleContentChange = (event: ChangeEvent<HTMLDivElement>) => {
    onChange?.(event);
  };

  // const modifiedText = text ? addLinkActionClass(text) : '';

  return (
    <StyledRichText
      data-component-name="m-ui-library-RichText"
      data-testid="ui-library-RichText"
      id={componentId}
      className={clsx(styleclass ? styleclass : '', customClass)}
    >
      {text && (
        <div
          contentEditable={contentEditable}
          dangerouslySetInnerHTML={{ __html: text }}
          onInput={handleContentChange}
          data-testid={componentId}
          {...customProps}
        ></div>
      )}
    </StyledRichText>
  );
};
