import { FC } from 'react';
import { baseVariables, toRem, Link, Types, Accordion } from '@marriott/mi-ui-library';

import { getClickTrackValue } from '../../utils/TrackingPropertyUtils';

import { FooterAEMModel } from './Footer.types';
import { DEFAULT_TRACKING_VALUES } from '../../utils/constants';

// added styled components for components that wouldn't override
import {
  StyledText,
  StyledP,
  DestinationWrapper,
  LinkWrapper,
  DestinationOnTopWrapper,
  DestinationColumn,
} from './Footer.styles';

import { parseBoolean } from './helper';

export const ExpandedFooter: FC<{ model: FooterAEMModel }> = ({ model }) => {
  const trackingPropertiesObject = {
    trackingProperties: {
      trackingContentPosition: model?.trackingProperties?.trackingContentPosition,
      clickTrack: model?.trackingProperties?.clickTrack,
      trackingOfferType: model?.trackingProperties?.trackingOfferType,
      impressionCount: model?.trackingProperties?.impressionCount,
      impressionTrack: model?.trackingProperties?.impressionTrack,
      trackingDescription: model?.trackingProperties?.trackingDescription,
      trackingTag: model?.trackingProperties?.trackingTag,
    },
    fallbacks: {
      position: model?.trackingProperties?.location ?? DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
      description: model?.trackingProperties?.trackingDescription ?? DEFAULT_TRACKING_VALUES.LABEL_NULLISH,
    },
  };

  // TODO: These components need to  be replaced with the LinkList components and Accordion components when the platform team are finished with their tickets
  return (
    <>
      {parseBoolean(model?.resourceProperties?.destinationOnTop) && (
        <DestinationOnTopWrapper className="mx-3 py-3">
          <Accordion
            styleclass="inverse"
            id="destination-on-top-accordion"
            isOpen={model?.resourceProperties?.destinationState === 'expanded' ? true : false}
            headerChildren={
              <StyledText
                element={Types.tags.span}
                copyText={model?.resourceProperties?.destinationTitle}
                fontSize={Types.size.large}
                customClass={'destinationTitle'}
              />
            }
          >
            <div className="col-12 m-accordion-container inverse">
              <DestinationColumn className="d-flex flex-column flex-md-row row">
                {model?.destinationItems?.map(destinationItem => {
                  return (
                    <div className="mb-1 col-md-3" key={destinationItem.linkLabel}>
                      <LinkWrapper>
                        <Link
                          target={parseBoolean(destinationItem.openInaNewTab) ? '_blank' : '_self'}
                          linkHref={destinationItem.linkURL}
                          text={destinationItem.linkLabel}
                          linkClassName="t-font-alt-s"
                          custom_click_track_value={getClickTrackValue(
                            trackingPropertiesObject,
                            destinationItem.linkLabel
                          )}
                          trackingProperties={trackingPropertiesObject.trackingProperties}
                        />
                      </LinkWrapper>
                    </div>
                  );
                })}
              </DestinationColumn>
            </div>
          </Accordion>
        </DestinationOnTopWrapper>
      )}

      <div className="col-12 m-accordion-container inverse px-4 pt-3">
        <div className="h-100 mt-5">
          {/* bootstrap borders didn't work here, opted for style */}
          <div
            className="d-flex flex-column flex-md-row row"
            style={{
              borderBottom: !parseBoolean(model?.resourceProperties?.destinationOnTop)
                ? `solid ${toRem(0.5)} ${baseVariables.color['neutral40']}`
                : 'none',
            }}
          >
            <div className="flex-column w-100 m-accordion-title col-md-3 p-0">
              <StyledP className="t-font-l mb-3">{model?.resourceProperties?.ourCompanyTitle}</StyledP>
              <div className="mb-5">
                {model?.ourCompanyItems?.map(companyItem => {
                  return (
                    <p key={companyItem.linkLabel}>
                      <LinkWrapper>
                        <Link
                          // workaround for the fact that openInNewTab is not functional
                          target={parseBoolean(companyItem.openInaNewTab) ? '_blank' : '_self'}
                          linkHref={companyItem.linkURL}
                          text={companyItem.linkLabel}
                          linkClassName="t-font-alt-s"
                          custom_click_track_value={getClickTrackValue(trackingPropertiesObject, companyItem.linkLabel)}
                          trackingProperties={trackingPropertiesObject.trackingProperties}
                        />
                      </LinkWrapper>
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="flex-column m-accordion-title col-md-3 p-0">
              <StyledP className="t-font-l mb-3 mt-2 mt-md-0">{model?.resourceProperties?.workWithUsTitle}</StyledP>
              <div className="mb-5">
                {model?.workwithusItems?.map(workWithUsItem => {
                  return (
                    <p key={workWithUsItem.linkLabel}>
                      <LinkWrapper>
                        <Link
                          target={parseBoolean(workWithUsItem.openInaNewTab) ? '_blank' : '_self'}
                          linkHref={workWithUsItem.linkURL}
                          text={workWithUsItem.linkLabel}
                          linkClassName="t-font-alt-s"
                          custom_click_track_value={getClickTrackValue(
                            trackingPropertiesObject,
                            workWithUsItem.linkLabel
                          )}
                          trackingProperties={trackingPropertiesObject.trackingProperties}
                        />
                      </LinkWrapper>
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="flex-column m-accordion-title col-md-3 p-0">
              <StyledP className="t-font-l mb-3 mt-2 mt-md-0">{model?.resourceProperties?.findHelpTitle}</StyledP>
              <div className="mb-5">
                {model?.findhelpItems?.map(findHelpItem => {
                  return (
                    <p key={findHelpItem.linkLabel}>
                      <LinkWrapper>
                        <Link
                          target={parseBoolean(findHelpItem.openInaNewTab) ? '_blank' : '_self'}
                          linkHref={findHelpItem.linkURL}
                          text={findHelpItem.linkLabel}
                          linkClassName="t-font-alt-s"
                          custom_click_track_value={getClickTrackValue(
                            trackingPropertiesObject,
                            findHelpItem.linkLabel
                          )}
                          trackingProperties={trackingPropertiesObject.trackingProperties}
                        />
                      </LinkWrapper>
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="flex-column m-accordion-title col-md-3 p-0">
              <StyledP className="t-font-l mb-3 mt-2 mt-md-0">{model?.resourceProperties?.privacyTitle}</StyledP>
              <div className="mb-5">
                {model?.privacyItems?.map(privacyItem => {
                  return (
                    <p key={privacyItem.linkLabel}>
                      <LinkWrapper>
                        <Link
                          target={parseBoolean(privacyItem.openInaNewTab) ? '_blank' : '_self'}
                          linkHref={privacyItem.linkURL}
                          text={privacyItem.linkLabel}
                          linkClassName="t-font-alt-s"
                          custom_click_track_value={getClickTrackValue(trackingPropertiesObject, privacyItem.linkLabel)}
                          trackingProperties={trackingPropertiesObject.trackingProperties}
                        />
                      </LinkWrapper>
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!parseBoolean(model?.resourceProperties?.destinationOnTop) && (
        <DestinationWrapper>
          <Accordion
            styleclass="inverse"
            id="default-destination-accordion"
            isOpen={model?.resourceProperties?.destinationState === 'expanded' ? true : false}
            customClass="px-3 pt-3"
            headerChildren={
              <StyledText
                element={Types.tags.span}
                copyText={model?.resourceProperties?.destinationTitle}
                fontSize={Types.size.large}
              />
            }
          >
            <div className="col-12 m-accordion-container inverse">
              <DestinationColumn className="d-flex flex-column flex-md-row row">
                {model?.destinationItems?.map(destinationItem => {
                  return (
                    <div className="mb-1 col-md-3" key={destinationItem.linkLabel}>
                      <LinkWrapper>
                        <Link
                          target={parseBoolean(destinationItem.openInaNewTab) ? '_blank' : '_self'}
                          linkHref={destinationItem.linkURL}
                          text={destinationItem.linkLabel}
                          linkClassName="t-font-alt-s"
                          custom_click_track_value={getClickTrackValue(
                            trackingPropertiesObject,
                            destinationItem.linkLabel
                          )}
                          trackingProperties={trackingPropertiesObject.trackingProperties}
                        />
                      </LinkWrapper>
                    </div>
                  );
                })}
              </DestinationColumn>
            </div>
          </Accordion>
        </DestinationWrapper>
      )}
    </>
  );
};
